import {useMemo, useState} from 'react';

import {FAQ_LIMIT} from 'constants/homePage';

import Button from '../Button';


const Accordion = ({faqs}) => {
  const [toggle, setToggle] = useState(true);
  const [activeIndex, setActiveIndex] = useState(undefined);
  const [isAllVisible, setIsAllVisible] = useState(false)

  const faqList = useMemo(() => {
    const updatedList = [...faqs]

    if (!isAllVisible) {
      updatedList.length = FAQ_LIMIT
    }

    return updatedList;

  }, [isAllVisible, faqs, FAQ_LIMIT]);

  const handleSetIndex = (index) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
      setToggle(!toggle);
    } else {
      setActiveIndex(undefined);
      setToggle(!toggle);
    }
  };

  return (
    <>
      <ul className="accordion">
        {faqList.map(({title, description}, index) => (
          <div
            key={`accordion-${index}`}
            onClick={() => handleSetIndex(index)}
            className="accordion__item"
          >
            <div
              className="accordion__item__heading"
              id={`accordion__heading-${index}`}
              aria-disabled="false"
              aria-expanded="false"
              aria-controls={`accordion__panel-${index}`}
              role="button"
            >
              <div className="accordion__item__heading__title">{title}</div>
              <div className="accordion__item__heading__sign">{activeIndex === index ? <>&#8211;</> : <>&#43;</>}</div>
            </div>
            {activeIndex === index && (
              <div
                className="accordion__item__description"
                aria-labelledby={`accordion__heading-${index}`}
                id={`accordion__panel-${index}`}
              >
                {description}
              </div>
            )}
          </div>
        ))}
      </ul>
      <Button btnText={isAllVisible ? "See less" : "See more"}
              onClick={() => setIsAllVisible(prevState => !prevState)}
              className="home-page__faq-section__button"
      />
    </>
  );
};

export default Accordion;
