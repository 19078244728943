import {ICONS} from 'constants/icons';
import {
  FAQS,
  HOME_PAGE_MAIN_SECTION,
  HOME_PAGE_SECTIONS, TELL_YOUR_FAMILY_STORY,
  TELL_YOUR_FRIENDSHIP_STORY,
  TELL_YOUR_STORY
} from 'constants/homePage';

import AppleButton from 'components/AppleButton';
import GoogleButton from 'components/GoogleButton';
import Header from 'components/Header';
import ListBlock from 'components/ListBlock';
import Accordion from 'components/Accordion';
import Icon from 'components/Icon';
import Footer from 'components/Footer';

import HeroImageSmall from 'assets/images/Image_profile.png';
import HeroImageMedium from 'assets/images/Image_profile@2x.png';
import iPhone12ProStory from 'assets/images/iPhone_12_pro_story.png';
import iPhone12ProAlbums from 'assets/images/iPhone_12_Pro_albums.png'
import iPhone12ProFamily from 'assets/images/iPhone_12_Pro_family.png'


function HomePage() {


  return (
    <>
      <Header>
        <ul className="home-page__main-section__nav__list">
          {Object.values(HOME_PAGE_SECTIONS).map((section => (
            <li key={section.section_id} className="home-page__main-section__nav__list-item">
              <a className="home-page__main-section__nav__list-item__link"
                 href={`#${section.section_id}`}>{section.nav_title}</a>
            </li>)))}
        </ul>
      </Header>

      <section className="home-page__main-section" id={HOME_PAGE_MAIN_SECTION}>
        <div>

          <div className="home-page__main-section__hero">

            <div className="home-page__main-section__hero__top section-content-container">

              <h1 className="home-page__main-section__hero__top__title">
                <div className="home-page__main-section__hero__top__title__decoration">
                  <Icon icon={ICONS.leftSplash} />
                </div>
                Everyone has a story to tell, build it, share it and preserve it – It’s
                <span className="color-orange">&nbsp;Yourstotell</span>
              </h1>
              <div className="home-page__main-section__hero__top__subtitle">A social network without the media, just
                you, your family and your friends
              </div>
              <div className="home-page__main-section__hero__top__text">Yourstotell allows you to connect with your
                family and friends to share and preserve your memories in story form by organizing photo, video, audio,
                and text files into albums and sub albums that combine to tell the story of your life.
              </div>

              <div className="download-buttons">
                <AppleButton />
                <GoogleButton />
              </div>
            </div>

            <picture className="home-page__main-section__hero__bottom">
              <source srcSet={HeroImageSmall} media="(max-width: 768px)" />
              <img className="home-page__main-section__hero__bottom__image" src={HeroImageMedium} alt="" />
            </picture>

          </div>

        </div>
      </section>

      <section className="home-page__about-section" id={HOME_PAGE_SECTIONS.about.section_id}>
        <div className="section-content-container">
          <div className="home-page__about-section__content">

            <h2 className="home-page__about-section__title">
              See and share only what you want. No Ads, feeds or promoted content, just your stories
            </h2>

            <div className="home-page__about-section__image-and-description with-arrow">
              <img className="home-page__about-section__image-and-description__image" src={iPhone12ProStory}
                   alt="iPhone 12 Pro" />
              <ListBlock titleNotHighlighted="Tell your" titleHighlighted="story" list={TELL_YOUR_STORY}
                         className="one" />
              <div className="home-page__about-section__image-and-description__curly-arrow">
                <Icon icon={ICONS.curlyArrow} />
              </div>

            </div>

            <div className="home-page__about-section__family-story__wrapper">
              <div className="home-page__about-section__image-and-description family-story__content">
                <div className="home-page__about-section__family-story__upper-layer">
                  <ListBlock titleNotHighlighted="Tell your" titleHighlighted="family story"
                             list={TELL_YOUR_FAMILY_STORY} className="two" showRightSplash />
                  <img className="home-page__about-section__image-and-description__image" src={iPhone12ProFamily}
                       alt="iPhone 12 Pro" />
                </div>
                <div className="home-page__about-section__family-story__shadow__wrapper">
                  <div className="home-page__about-section__family-story__shadow__content"></div>
                </div>
              </div>
            </div>

            <div className="home-page__about-section__image-and-description">
              <img className="home-page__about-section__image-and-description__image" src={iPhone12ProAlbums}
                   alt="iPhone 12 Pro" />
              <ListBlock titleNotHighlighted="Tell your" titleHighlighted="friendship story"
                         list={TELL_YOUR_FRIENDSHIP_STORY} className="three" />
            </div>

          </div>
        </div>
      </section>

      <section className="home-page__faq-section" id={HOME_PAGE_SECTIONS.faq.section_id}>
        <div className="home-page__faq-section__content section-content-container">

          <h2 className="home-page__faq-section__title">
            FAQ
          </h2>

          <Accordion faqs={FAQS} />

        </div>
      </section>

      <section className="home-page__download-section">
        <div className="section-content-container">
          <div className="home-page__download-section__content">

            <div className="home-page__download-section__upper-layer">
              <h2 className="home-page__download-section__title">
                <p>
                  <span className="color-orange">Download&nbsp;</span>
                  now
                  <Icon icon={ICONS.rightSplash} />
                </p>
                <p>and start your experience</p>
              </h2>

              <div className="home-page__download-section__buttons-container">
                <Icon icon={ICONS.leftCurlyArrow} />
                <div className="download-buttons">
                  <AppleButton />
                  <GoogleButton />
                </div>
              </div>
            </div>

            <div className="home-page__download-section__shadow"></div>

          </div>
        </div>
      </section>

      <Footer />

    </>
  );
}

export default HomePage;


