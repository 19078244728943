import {useEffect, useState} from 'react';

import {HEADER_SCROLL_POSITION} from 'constants/homePage';

export const useHeaderScrollStatus = () => {
  const [scrolled, setScrolled] = useState(false);

  const changeScrolledStatus = () => {
    window.scrollY >= HEADER_SCROLL_POSITION ? setScrolled(true) : setScrolled(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', changeScrolledStatus);
    return () => {
      window.removeEventListener('scroll', changeScrolledStatus);
    }
  }, [])

  return [scrolled]
}
