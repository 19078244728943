const GoogleButton = () => {
  return (
    <a
      href='https://play.google.com/store/apps/details?id=com.yourstotell.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
      alt='Get it on Google Play'
      src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
      className="download-buttons__google" />
    </a>
  )
}

export default GoogleButton;
