import {Link} from 'react-router-dom';

import {routes} from 'constants/navigation';

const Footer = () => {

  return (
    <footer className="home-page__footer">
      <div className="home-page__footer__content section-content-container">
        <div className="home-page__footer__links-section">

          <Link className="home-page__main-section__nav__logo" to={routes.ROOT.path}>
            <img src="/logo-group.svg"
                 alt="Logo" />
          </Link>

          <div className="home-page__footer__links-section__links-list">
            <div className="home-page__footer__links-section__email">support@yourstotell.com</div>

            <Link to={routes.TERMS_OF_USE.path} className="home-page__footer__links-section__links-list__link">
              {routes.TERMS_OF_USE.name}
            </Link>

            <Link to={routes.PRIVACY_POLICY.path} className="home-page__footer__links-section__links-list__link">
              {routes.PRIVACY_POLICY.name}
            </Link>

          </div>

        </div>
        <div className="home-page__footer__copyright-section">
          ©2023 All rights reserved
        </div>
      </div>

    </footer>
  )
}

export default Footer;
