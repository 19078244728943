import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import HomePage from 'pages/HomePage';
import TermsOfUse from 'pages/TermsOfUse';
import PrivacyPolicy from 'pages/PrivacyPolicy';

import {routes} from 'constants/navigation';
import ScrollToTop from 'helpers/scrollToTop';

import './App.css';



function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path={routes.TERMS_OF_USE.path} element={<TermsOfUse />} />
          <Route path={routes.PRIVACY_POLICY.path} element={<PrivacyPolicy />} />
          <Route path={routes.ROOT.path} element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
