import {Link} from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Icon from 'components/Icon';

import {ICONS} from 'constants/icons';
import {routes} from 'constants/navigation';


const TermsOfUse = () => {


  return (
    <>

      <Header>

        <ul className="home-page__main-section__nav__list">
          <li className="home-page__main-section__nav__list-item">
            <Link className="home-page__main-section__nav__list-item__link"
                  to={routes.ROOT.path}>{routes.ROOT.name}</Link>
          </li>
        </ul>

      </Header>

      <main className="policies__main">
        <div className="policies__main__content section-content-container">

          <h1 className="policies__main__title">Terms and conditions</h1>
          <span className="policies__main__title__decoration"><Icon icon={ICONS.policyHeadingDecoration} /></span>

          <div className="policies__main__text">
            <p>
              These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the
              “Yourstotell” mobile application (“Mobile Application” or “Service”) and any of its related products and
              services (collectively, “Services”). This Agreement is legally binding between you (“User”, “you” or
              “your”)
              and LTMAssets (“LTMAssets”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a
              business or other legal entity, you represent that you have the authority to bind such entity to this
              agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have
              such authority, or if you do not agree with the terms of this agreement, you must not accept this
              agreement
              and may not access and use the Mobile Application and Services. By accessing and using the Mobile
              Application and Services, you acknowledge that you have read, understood, and agree to be bound by the
              terms
              of this Agreement. You acknowledge that this Agreement is a contract between you and LTMAssets, even
              though
              it is electronic and is not physically signed by you, and it governs your use of the Mobile Application
              and
              Services.
            </p>
            <div className="policies__main__text__subtitle">
              Accounts and membership
            </div>
            <p>
              If you create an account in the Mobile Application, you are responsible for maintaining the security of
              your
              account and you are fully responsible for all activities that occur under the account and any other
              actions
              taken in connection with it. We may monitor and review new accounts before you may sign in and start using
              the Services. Providing false contact information of any kind may result in the termination of your
              account.
              You must immediately notify us of any unauthorized uses of your account or any other breaches of security.
              We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a
              result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if
              we determine that you have violated any provision of this Agreement or that your conduct or content would
              tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may
              not
              re-register for our Services. We may block your email address and Internet protocol address to prevent
              further registration.
            </p>
            <div className="policies__main__text__subtitle">
              User content
            </div>
            <p>
              We do not own any data, information or material (collectively, “Content”) that you submit in the Mobile
              Application in the course of using the Service. You shall have sole responsibility for the accuracy,
              quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right
              to
              use of all submitted Content. We may, but have no obligation to, monitor and review the Content in the
              Mobile Application submitted or created using our Services by you. You grant us permission to access,
              copy,
              distribute, store, transmit, reformat, display and perform the Content of your user account solely as
              required for the purpose of providing the Services to you. Without limiting any of those representations
              or
              warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove
              any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or
              objectionable. Unless specifically permitted by you, your use of the Mobile Application and Services does
              not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by
              you
              or stored in your user account for commercial, marketing or any similar purpose.
            </p>
            <div className="policies__main__text__subtitle">
              Adult content
            </div>
            <p>
              Please be aware that there may be certain adult or mature content available in the Mobile Application.
              Where
              there is mature or adult content, individuals who are less than 18 years of age or are not permitted to
              access such content under the laws of any applicable jurisdiction may not access such content. If we learn
              that anyone under the age of 18 seeks to conduct a transaction through the Services, we will require
              verified parental consent, in accordance with the Children’s Online Privacy Protection Act of 1998
              (“COPPA”). Certain areas of the Mobile Application and Services may not be available to children under 18
              under any circumstances.
            </p>
            <div className="policies__main__text__subtitle">
              Billing and payments
            </div>
            <p>
              You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms
              in
              effect at the time a fee or charge is due and payable. Where Services are offered on a free trial basis,
              payment may be required after the free trial period ends, and not when you enter your billing details
              (which
              may be required prior to the commencement of the free trial period). If auto-renewal is enabled for the
              Services you have subscribed for, you will be charged automatically in accordance with the term you
              selected. Sensitive and private data exchange happens over a SSL secured communication channel and is
              encrypted and protected with digital signatures, and the Mobile Application and Services are also in
              compliance with PCI vulnerability standards in order to create as secure of an environment as possible for
              Users. Scans for malware are performed on a regular basis for additional security and protection. If, in
              our
              judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy
              of your valid government-issued photo identification, and possibly a copy of a recent bank statement for
              the
              credit or debit card used for the purchase. We reserve the right to change products and product pricing at
              any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion,
              limit or cancel quantities purchased per person, per household or per order. These restrictions may
              include
              orders placed by or under the same customer account, the same credit card, and/or orders that use the same
              billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt
              to
              notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was
              made.
            </p>
            <div className="policies__main__text__subtitle">
              Accuracy of information
            </div>
            <p>
              Occasionally there may be information in the Mobile Application that contains typographical errors,
              inaccuracies or omissions that may relate to product descriptions, availability, promotions and offers. We
              reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or
              cancel orders if any information in the Mobile Application or Services is inaccurate at any time without
              prior notice (including after you have submitted your order). We undertake no obligation to update, amend
              or
              clarify information in the Mobile Application including, without limitation, pricing information, except
              as
              required by law. No specified update or refresh date applied in the Mobile Application should be taken to
              indicate that all information in the Mobile Application or Services has been modified or updated.
            </p>
            <div className="policies__main__text__subtitle">
              Backups
            </div>
            <p>
              We are not responsible for the Content residing in the Mobile Application. In no event shall we be held
              liable for any loss of any Content. It is your sole responsibility to maintain appropriate backup of your
              Content. Notwithstanding the foregoing, on some occasions and in certain circumstances, with absolutely no
              obligation, we may be able to restore some or all of your data that has been deleted as of a certain date
              and time when we may have backed up data for our own purposes. We make no guarantee that the data you need
              will be available.
            </p>
            <div className="policies__main__text__subtitle">
              Links to other resources
            </div>
            <p>
              Although the Mobile Application and Services may link to other resources (such as websites, mobile
              applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship,
              endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not
              responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or
              individuals or the content of their resources. We do not assume any responsibility or liability for the
              actions, products, services, and content of any other third parties. You should carefully review the legal
              statements and other conditions of use of any resource which you access through a link in the Mobile
              Application. Your linking to any other off-site resources is at your own risk.
            </p>
            <div className="policies__main__text__subtitle">
              Prohibited uses
            </div>
            <p>
              In addition to other terms as set forth in the Agreement, you are prohibited from using the Mobile
              Application and Services or Content: (a) for any unlawful purpose; (b) to solicit others to perform or
              participate in any unlawful acts; (c) to violate any international, federal, provincial or state
              regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property
              rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander,
              disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race,
              age,
              national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit
              viruses or any other type of malicious code that will or may be used in any way that will affect the
              functionality or operation of the Mobile Application and Services, third party products and services, or
              the
              Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral
              purpose; or (j) to interfere with or circumvent the security features of the Mobile Application and
              Services, third party products and services, or the Internet. We reserve the right to terminate your use
              of
              the Mobile Application and Services for violating any of the prohibited uses.
            </p>
            <div className="policies__main__text__subtitle">
              Intellectual property rights
            </div>
            <p>
              “Intellectual Property Rights” means all present and future rights conferred by statute, common law or
              equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions,
              goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other
              intellectual property rights, in each case whether registered or unregistered and including all
              applications
              and rights to apply for and be granted, rights to claim priority from, such rights and all similar or
              equivalent rights or forms of protection and any other results of intellectual activity which subsist or
              will subsist now or in the future in any part of the world. This Agreement does not transfer to you any
              intellectual property owned by LTMAssets or third parties, and all rights, titles, and interests in and to
              such property will remain (as between the parties) solely with LTMAssets. All trademarks, service marks,
              graphics and logos used in connection with the Mobile Application and Services, are trademarks or
              registered
              trademarks of LTMAssets or its licensors. Other trademarks, service marks, graphics and logos used in
              connection with the Mobile Application and Services may be the trademarks of other third parties. Your use
              of the Mobile Application and Services grants you no right or license to reproduce or otherwise use any of
              LTMAssets or third party trademarks.
            </p>
            <div className="policies__main__text__subtitle">
              Disclaimer of warranty
            </div>
            <p>
              You agree that such Service is provided on an “as is” and “as available” basis and that your use of the
              Mobile Application and Services is solely at your own risk. We expressly disclaim all warranties of any
              kind, whether express or implied, including but not limited to the implied warranties of merchantability,
              fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet
              your
              requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any
              warranty as to the results that may be obtained from the use of the Service or as to the accuracy or
              reliability of any information obtained through the Service or that defects in the Service will be
              corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through
              the use of Service is done at your own discretion and risk and that you will be solely responsible for any
              damage or loss of data that results from the download of such material and/or data. We make no warranty
              regarding any goods or services purchased or obtained through the Service or any transactions entered into
              through the Service unless stated otherwise. No advice or information, whether oral or written, obtained
              by
              you from us or through the Service shall create any warranty not expressly made herein.
            </p>
            <div className="policies__main__text__subtitle">
              Limitation of liability
            </div>
            <p>
              To the fullest extent permitted by applicable law, in no event will LTMAssets, its affiliates, directors,
              officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental,
              special, punitive, cover or consequential damages (including, without limitation, damages for lost
              profits,
              revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated
              savings, loss of business opportunity) however caused, under any theory of liability, including, without
              limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the
              liable
              party has been advised as to the possibility of such damages or could have foreseen such damages. To the
              maximum extent permitted by applicable law, the aggregate liability of LTMAssets and its affiliates,
              officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount
              no greater than one dollar or any amounts actually paid in cash by you to LTMAssets for the prior one
              month
              period prior to the first event or occurrence giving rise to such liability. The limitations and
              exclusions
              also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.
            </p>
            <div className="policies__main__text__subtitle">
              Indemnification
            </div>
            <p>
              You agree to indemnify and hold LTMAssets and its affiliates, directors, officers, employees, agents,
              suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including
              reasonable attorneys’ fees, incurred in connection with or arising from any third party allegations,
              claims,
              actions, disputes, or demands asserted against any of them as a result of or relating to your Content,
              your
              use of the Mobile Application and Services or any willful misconduct on your part.
            </p>
            <div className="policies__main__text__subtitle">
              Severability
            </div>
            <p>
              All rights and restrictions contained in this Agreement may be exercised and shall be applicable and
              binding
              only to the extent that they do not violate any applicable laws and are intended to be limited to the
              extent
              necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision
              or
              portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a
              court
              of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions
              thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining
              provisions or portions thereof shall remain in full force and effect.
            </p>
            <div className="policies__main__text__subtitle">
              Dispute resolution
            </div>
            <p>
              The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall
              be
              governed by the substantive and procedural laws of Connecticut, United States without regard to its rules
              on
              conflicts or choice of law and, to the extent applicable, the laws of United States. The exclusive
              jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in
              Connecticut, United States, and you hereby submit to the personal jurisdiction of such courts. You hereby
              waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United
              Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.
            </p>
            <div className="policies__main__text__subtitle">
              Assignment
            </div>
            <p>
              You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights or
              obligations
              hereunder, in whole or in part, without our prior written consent, which consent shall be at our own sole
              discretion and without obligation; any such assignment or transfer shall be null and void. We are free to
              assign any of its rights or obligations hereunder, in whole or in part, to any third party as part of the
              sale of all or substantially all of its assets or stock or as part of a merger.
            </p>
            <div className="policies__main__text__subtitle">
              Changes and amendments
            </div>
            <p>
              We reserve the right to modify this Agreement or its terms related to the Mobile Application and Services
              at
              any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may
              also provide notice to you in other ways at our discretion, such as through the contact information you
              have
              provided.
            </p>
            <p>
              An updated version of this Agreement will be effective immediately upon the posting of the revised
              Agreement
              unless otherwise specified. Your continued use of the Mobile Application and Services after the effective
              date of the revised Agreement (or such other act specified at that time) will constitute your consent to
              those changes.
            </p>
            <div className="policies__main__text__subtitle">
              Acceptance of these terms
            </div>
            <p>
              You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing
              and using the Mobile Application and Services you agree to be bound by this Agreement. If you do not agree
              to abide by the terms of this Agreement, you are not authorized to access or use the Mobile Application
              and
              Services.
            </p>
            <div className="policies__main__text__subtitle">
              Contacting us
            </div>
            <p>
              If you have any questions, concerns, or complaints regarding this Agreement, we encourage you to contact
              us
              using the details below:
            </p>
            <p>
              support@yourstotell.com
            </p>
            <p>
              This document was last updated on October 13, 2022
            </p>
          </div>
        </div>
      </main>

      <Footer />
    </>
  )
}

export default TermsOfUse;
