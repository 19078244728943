const Button = ({
                  btnText,
                  onClick,
                  isDisabled = false,
                  className = '',
                  type = 'button'
                }) => {
  return (
    <button
      className={`button ${className}`}
      onClick={onClick}
      disabled={isDisabled}
      type={type}
    >
      {btnText}
    </button>
  );
}

export default Button;
