const AppleButton = () => {
  return (
    <a href="https://apps.apple.com/us/app/yourstotell/id6443811519?itsct=apps_box_badge&amp;itscg=30200"
       style={{display: "inline-block", overflow: "hidden", borderRadius: "13px"}}
       className="download-buttons__ios">
      <img
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1678665600"
        alt="Download on the App Store" style={{borderRadius: "13px"}} />
    </a>
  )
}

export default AppleButton;
