import React from 'react'

import {ICONS} from 'constants/icons';

import {ReactComponent as leftSplash} from 'assets/icons/ic-left-splash.svg'
import {ReactComponent as rightSplash} from 'assets/icons/ic-rightSplash.svg'
import {ReactComponent as listBullet} from 'assets/icons/ic-list-bullet.svg'
import {ReactComponent as curlyArrow} from 'assets/icons/ic-curly_arrow.svg'
import {ReactComponent as leftCurlyArrow} from 'assets/icons/ic-left-curly-arrow.svg'
import {ReactComponent as policyHeadingDecoration} from 'assets/icons/ic_decoration5.svg'


const icons = {
  [ICONS.leftSplash]: leftSplash,
  [ICONS.rightSplash]: rightSplash,
  [ICONS.listBullet]: listBullet,
  [ICONS.curlyArrow]: curlyArrow,
  [ICONS.leftCurlyArrow]: leftCurlyArrow,
  [ICONS.policyHeadingDecoration]: policyHeadingDecoration
}

const Icon = ({icon}) => {
  const Icon = icons[icon]

  return <Icon />

}

export default Icon
