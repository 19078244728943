import {Link} from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Icon from 'components/Icon';

import {routes} from 'constants/navigation';
import {ICONS} from 'constants/icons';


const PrivacyPolicy = () => {


  return (<>
      <Header>

        <ul className="home-page__main-section__nav__list">
          <li className="home-page__main-section__nav__list-item">
            <Link className="home-page__main-section__nav__list-item__link"
                  to={routes.ROOT.path}>{routes.ROOT.name}</Link>
          </li>
        </ul>

      </Header>

      <main className="policies__main">
        <div className="policies__main__content section-content-container">

          <h1 className="policies__main__title">Privacy policy</h1>
          <span className="policies__main__title__decoration"><Icon icon={ICONS.policyHeadingDecoration} /></span>

          <div className="policies__main__text">
            <p>
              We respect your privacy and are committed to protecting it through our compliance with this privacy policy
              (“Policy”). This Policy describes the types of information we may collect from you or that you may provide
              (“Personal Information”) in the “Yourstotell” mobile application (“Mobile Application” or “Service”) and
              any
              of its related products and services (collectively, “Services”), and our practices for collecting, using,
              maintaining, protecting, and disclosing that Personal Information. It also describes the choices available
              to you regarding our use of your Personal Information and how you can access and update it.
            </p>
            <p>
              This Policy is a legally binding agreement between you (“User”, “you” or “your”) and LTMAssets
              (“LTMAssets”,
              “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal
              entity,
              you represent that you have the authority to bind such entity to this agreement, in which case the terms
              “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not
              agree with the terms of this agreement, you must not accept this agreement and may not access and use the
              Mobile Application and Services. By accessing and using the Mobile Application and Services, you
              acknowledge
              that you have read, understood, and agree to be bound by the terms of this Policy. This Policy does not
              apply to the practices of companies that we do not own or control, or to individuals that we do not employ
              or manage.
            </p>
            <div className="policies__main__text__subtitle">
              Automatic collection of information
            </div>
            <p>
              When you use the Mobile Application, our servers automatically record information that your device sends.
              This data may include information such as your device’s IP address and location, device name and version,
              operating system type and version, language preferences, information you search for in the Mobile
              Application, access times and dates, and other statistics.
            </p>
            <p>
              Information collected automatically is used only to identify potential cases of abuse and establish
              statistical information regarding the usage of the Mobile Application and Services. This statistical
              information is not otherwise aggregated in such a way that would identify any particular User of the
              system.
            </p>
            <div className="policies__main__text__subtitle">
              Collection of personal information
            </div>
            <p>
              You can access and use the Mobile Application and Services without telling us who you are or revealing any
              information by which someone could identify you as a specific, identifiable individual. If, however, you
              wish to use some of the features offered in the Mobile Application, you may be asked to provide certain
              Personal Information (for example, your name and e-mail address).
            </p>
            <p>
              We receive and store any information you knowingly provide to us when you create an account, publish
              content, make a purchase, or fill any forms in the Mobile Application. When required, this information may
              include the following:
            </p>

            <ul>
              <li>Account details (such as user name, unique user ID, password, etc)</li>
              <li>Contact information (such as email address, phone number, etc)</li>
              <li>Basic personal information (such as name, country of residence, etc)</li>
              <li>Geolocation data of your device (such as latitude and longitude)</li>
              <li>Certain features on the mobile device (such as contacts, calendar, gallery, etc)</li>
              <li>Information about other individuals (such as your family members, friends, etc)</li>
              <li>Any other materials you willingly submit to us (such as articles, images, feedback, etc)</li>
            </ul>

            <p>
              Some of the information we collect is directly from you via the Mobile Application and Services. However,
              we
              may also collect Personal Information about you from other sources such as social media platforms, public
              databases, third-party data providers, and our joint partners. Personal Information we collect from other
              sources may include demographic information, such as age and gender, device information, such as IP
              addresses, location, such as city and state, and online behavioral data, such as information about your
              use
              of social media websites, page view information and search results and links.
            </p>
            <p>
              You can choose not to provide us with your Personal Information, but then you may not be able to take
              advantage of some of the features in the Mobile Application. Users who are uncertain about what
              information
              is mandatory are welcome to contact us.
            </p>
            <div className="policies__main__text__subtitle">
              Privacy of children
            </div>
            <p>
              We recognize the need to provide further privacy protections with respect to Personal Information we may
              collect from children under the age of 13 and take many special precautions to protect their privacy. We
              may
              require a child to disclose some Personal Information to use the Mobile Application and Services. However,
              we do not require a child to disclose more information than is reasonably necessary to use the Mobile
              Application and Services and Personal Information will not be used or disclosed for purposes other than
              those for which it was collected. All of the information created or submitted by a child is set to private
              by default, meaning only the User who submitted it may view it. Parents and legal guardians can review
              their
              child’s Personal Information, delete it, and refuse to allow any further collection or use of such
              information.
            </p>
            <p>
              We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce
              this
              Policy by instructing their children never to provide Personal Information through the Mobile Application
              and Services without their permission. We also ask that all parents and legal guardians overseeing the
              care
              of children take the necessary precautions to ensure that their children are instructed to never give out
              Personal Information when online without their permission. We believe parents and legal guardians should
              be
              involved in the online activities of their children and suggest that parents do their best to provide
              their
              children with a safe and friendly online environment.
            </p>
            <div className="policies__main__text__subtitle">
              Use and processing of collected information
            </div>
            <p>
              We act as a data controller and a data processor when handling Personal Information, unless we have
              entered
              into a data processing agreement with you in which case you would be the data controller and we would be
              the
              data processor.
            </p>
            <p>
              Our role may also differ depending on the specific situation involving Personal Information. We act in the
              capacity of a data controller when we ask you to submit your Personal Information that is necessary to
              ensure your access and use of the Mobile Application and Services. In such instances, we are a data
              controller because we determine the purposes and means of the processing of Personal Information.
            </p>
            <p>
              We act in the capacity of a data processor in situations when you submit Personal Information through the
              Mobile Application and Services. We do not own, control, or make decisions about the submitted Personal
              Information, and such Personal Information is processed only in accordance with your instructions. In such
              instances, the User providing Personal Information acts as a data controller.
            </p>
            <p>
              In order to make the Mobile Application and Services available to you, or to meet a legal obligation, we
              may
              need to collect and use certain Personal Information. If you do not provide the information that we
              request,
              we may not be able to provide you with the requested products or services. Any of the information we
              collect
              from you may be used for the following purposes:
            </p>

            <ul>
              <li>Create and manage user accounts</li>
              <li>Fulfill and manage orders</li>
              <li>Deliver products or services</li>
              <li>Improve products and services</li>
              <li>Send administrative information</li>
              <li>Send marketing and promotional communications</li>
              <li>Send product and service updates</li>
              <li>Respond to inquiries and offer support</li>
              <li>Request user feedback</li>
              <li>Improve user experience</li>
              <li>Post customer testimonials</li>
              <li>Enforce terms and conditions and policies</li>
              <li>Protect from abuse and malicious users</li>
              <li>Respond to legal requests and prevent harm</li>
              <li>Run and operate the Mobile Application and Services</li>
            </ul>

            <p>
              Processing your Personal Information depends on how you interact with the Mobile Application and Services,
              where you are located in the world and if one of the following applies: (i) you have given your consent
              for
              one or more specific purposes; this, however, does not apply, whenever the processing of Personal
              Information is subject to California Consumer Privacy Act; (ii) provision of information is necessary for
              the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii)
              processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing
              is
              related to a task that is carried out in the public interest or in the exercise of official authority
              vested
              in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a
              third
              party. We may also combine or aggregate some of your Personal Information in order to better serve you and
              to improve and update our Mobile Application and Services.
            </p>
            <p>
              Note that under some legislations we may be allowed to process information until you object to such
              processing by opting out, without having to rely on consent or any other of the legal bases. In any case,
              we
              will be happy to clarify the specific legal basis that applies to the processing, and in particular
              whether
              the provision of Personal Information is a statutory or contractual requirement, or a requirement
              necessary
              to enter into a contract.
            </p>
            <div className="policies__main__text__subtitle">
              Payment processing
            </div>
            <p>
              In case of Services requiring payment, you may need to provide your credit card details or other payment
              account information, which will be used solely for processing payments. We use third-party payment
              processors (“Payment Processors”) to assist us in processing your payment information securely.
            </p>
            <p>
              Payment Processors adhere to the latest security standards as managed by the PCI Security Standards
              Council,
              which is a joint effort of brands like Visa, MasterCard, American Express and Discover. Sensitive and
              private data exchange happens over a SSL secured communication channel and is encrypted and protected with
              digital signatures, and the Mobile Application and Services are also in compliance with strict
              vulnerability
              standards in order to create as secure of an environment as possible for Users. We will share payment data
              with the Payment Processors only to the extent necessary for the purposes of processing your payments,
              refunding such payments, and dealing with complaints and queries related to such payments and refunds.
            </p>
            <p>
              Please note that the Payment Processors may collect some Personal Information from you, which allows them
              to
              process your payments (e.g., your email address, address, credit card details, and bank account number)
              and
              handle all the steps in the payment process through their systems, including data collection and data
              processing. The Payment Processors’ use of your Personal Information is governed by their respective
              privacy
              policies which may or may not contain privacy protections as protective as this Policy. We suggest that
              you
              review their respective privacy policies.
            </p>
            <div className="policies__main__text__subtitle">
              Managing information
            </div>
            <p>
              You are able to delete certain Personal Information we have about you. The Personal Information you can
              delete may change as the Mobile Application and Services change. When you delete Personal Information,
              however, we may maintain a copy of the unrevised Personal Information in our records for the duration
              necessary to comply with our obligations to our affiliates and partners, and for the purposes described
              below. If you would like to delete your Personal Information or permanently delete your account, you can
              do
              so on the settings page of your account in the Mobile Application.
            </p>
            <div className="policies__main__text__subtitle">
              Disclosure of information
            </div>
            <p>
              Depending on the requested Services or as necessary to complete any transaction or provide any Service you
              have requested, we may share your information with our affiliates, contracted companies, and service
              providers (collectively, “Service Providers”) we rely upon to assist in the operation of the Mobile
              Application and Services available to you and whose privacy policies are consistent with ours or who agree
              to abide by our policies with respect to Personal Information. We will not share any personally
              identifiable
              information with third parties and will not share any information with unaffiliated third parties.
            </p>
            <p>
              Service Providers are not authorized to use or disclose your information except as necessary to perform
              services on our behalf or comply with legal requirements. Service Providers are given the information they
              need only in order to perform their designated functions, and we do not authorize them to use or disclose
              any of the provided information for their own marketing or other purposes.
            </p>
            <p>
              We may also disclose any Personal Information we collect, use or receive if required or permitted by law,
              such as to comply with a subpoena or similar legal process, and when we believe in good faith that
              disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate
              fraud, or respond to a government request.
            </p>
            <p>
              In the event we go through a business transition, such as a merger or acquisition by another company, or
              sale of all or a portion of its assets, your user account, and your Personal Information will likely be
              among the assets transferred.
            </p>
            <div className="policies__main__text__subtitle">
              Retention of information
            </div>
            <p>
              We will retain and use your Personal Information for the period necessary as long as your user account
              remains active, to enforce our agreements, resolve disputes, and unless a longer retention period is
              required or permitted by law.
            </p>
            <p>
              We may use any aggregated data derived from or incorporating your Personal Information after you update or
              delete it, but not in a manner that would identify you personally. Once the retention period expires,
              Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to
              rectification, and the right to data portability cannot be enforced after the expiration of the retention
              period.
            </p>
            <div className="policies__main__text__subtitle">
              California privacy rights
            </div>
            <p>
              Consumers residing in California are afforded certain additional rights with respect to their Personal
              Information under the California Consumer Privacy Act (“CCPA”). If you are a California resident, this
              section applies to you.
            </p>
            <p>
              As described in this Policy in the information collection section above, we have collected the categories
              of
              Personal Information listed below in the past twelve (12) months:
            </p>


            <ul>
              <li>Personal identifiers (such as email address, phone number, etc)</li>
              <li>Person’s characteristics (such as age, gender, etc)</li>
              <li>Audio, visual, electronic, or other types of recordings</li>
              <li>Professional or employment information</li>
              <li>Internet or other electronic network activity</li>
              <li>Geolocation data</li>
            </ul>

            <p>
              In addition to the rights as explained in this Policy, California residents who provide Personal
              Information
              as defined in the statute to obtain Services for personal, family, or household use are entitled to
              request
              and obtain from us, once a calendar year, information about the categories and specific pieces of Personal
              Information we have collected and disclosed.
            </p>
            <p>
              Furthermore, California residents have the right to request deletion of their Personal Information or
              opt-out of the sale of their Personal Information which may include selling, disclosing, or transferring
              Personal Information to another business or a third party for monetary or other valuable consideration. To
              do so, simply contact us. We will not discriminate against you if you exercise your rights under the CCPA.
            </p>
            <div className="policies__main__text__subtitle">
              How to exercise your rights
            </div>
            <p>
              Any requests to exercise your rights can be directed to us through the contact details provided in this
              document. Please note that we may ask you to verify your identity before responding to such requests. Your
              request must provide sufficient information that allows us to verify that you are the person you are
              claiming to be or that you are the authorized representative of such person. If we receive your request
              from
              an authorized representative, we may request evidence that you have provided such an authorized
              representative with power of attorney or that the authorized representative otherwise has valid written
              authority to submit requests on your behalf.
            </p>
            <p>
              You must include sufficient details to allow us to properly understand the request and respond to it. We
              cannot respond to your request or provide you with Personal Information unless we first verify your
              identity
              or authority to make such a request and confirm that the Personal Information relates to you.
            </p>
            <div className="policies__main__text__subtitle">
              Data analytics
            </div>
            <p>
              Our Mobile Application and Services may use third-party analytics tools that use cookies, web beacons, or
              other similar information-gathering technologies to collect standard internet activity and usage
              information. The information gathered is used to compile statistical reports on User activity such as how
              often Users visit our Mobile Application and Services, what pages they visit and for how long, etc. We use
              the information obtained from these analytics tools to monitor the performance and improve our Mobile
              Application and Services.
            </p>
            <div className="policies__main__text__subtitle">
              Advertisements
            </div>
            <p>
              We may permit certain third-party companies to help us tailor advertising that we think may be of interest
              to Users and to collect and use other data about User activities in the Mobile Application. These
              companies
              may deliver ads that might place cookies and otherwise track User behavior.
            </p>
            <div className="policies__main__text__subtitle">
              Social media features
            </div>
            <p>
              Our Mobile Application and Services may include social media features, such as the Facebook and Twitter
              buttons, Share This buttons, etc (collectively, “Social Media Features”). These Social Media Features may
              collect your IP address, what page you are visiting on our Mobile Application and Services, and may set a
              cookie to enable Social Media Features to function properly. Social Media Features are hosted either by
              their respective providers or directly on our Mobile Application and Services. Your interactions with
              these
              Social Media Features are governed by the privacy policy of their respective providers.
            </p>
            <div className="policies__main__text__subtitle">
              Push notifications
            </div>
            <p>
              We offer push notifications to which you may voluntarily subscribe at any time. To make sure push
              notifications reach the correct devices, we use a third-party push notifications provider who relies on a
              device token unique to your device which is issued by the operating system of your device. While it is
              possible to access a list of device tokens, they will not reveal your identity, your unique device ID, or
              your contact information to us or our third-party push notifications provider. We will maintain the
              information sent via e-mail in accordance with applicable laws and regulations. If, at any time, you wish
              to
              stop receiving push notifications, simply adjust your device settings accordingly.
            </p>
            <div className="policies__main__text__subtitle">
              Links to other resources
            </div>
            <p>
              The Mobile Application and Services contain links to other resources that are not owned or controlled by
              us.
              Please be aware that we are not responsible for the privacy practices of such other resources or third
              parties. We encourage you to be aware when you leave the Mobile Application and Services and to read the
              privacy statements of each and every resource that may collect Personal Information.
            </p>
            <div className="policies__main__text__subtitle">
              Information security
            </div>
            <p>
              We secure information you provide on computer servers in a controlled, secure environment, protected from
              unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical
              safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of
              Personal Information in our control and custody. However, no data transmission over the Internet or
              wireless
              network can be guaranteed.
            </p>
            <p>
              Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are
              security
              and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and
              privacy of any and all information and data exchanged between you and the Mobile Application and Services
              cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by
              a
              third party, despite best efforts.
            </p>
            <p>
              As the security of Personal Information depends in part on the security of the device you use to
              communicate
              with us and the security you use to protect your credentials, please take appropriate measures to protect
              this information.
            </p>
            <div className="policies__main__text__subtitle">
              Data breach
            </div>
            <p>
              In the event we become aware that the security of the Mobile Application and Services has been compromised
              or Users’ Personal Information has been disclosed to unrelated third parties as a result of external
              activity, including, but not limited to, security attacks or fraud, we reserve the right to take
              reasonably
              appropriate measures, including, but not limited to, investigation and reporting, as well as notification
              to
              and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable
              efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the User
              as
              a result of the breach or if notice is otherwise required by law. When we do, we will post a notice in the
              Mobile Application, send you an email.
            </p>
            <div className="policies__main__text__subtitle">
              Changes and amendments
            </div>
            <p>
              We reserve the right to modify this Policy or its terms related to the Mobile Application and Services at
              any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may
              also provide notice to you in other ways at our discretion, such as through the contact information you
              have
              provided.
            </p>
            <p>
              An updated version of this Policy will be effective immediately upon the posting of the revised Policy
              unless otherwise specified. Your continued use of the Mobile Application and Services after the effective
              date of the revised Policy (or such other act specified at that time) will constitute your consent to
              those
              changes. However, we will not, without your consent, use your Personal Information in a manner materially
              different than what was stated at the time your Personal Information was collected.
            </p>
            <div className="policies__main__text__subtitle">
              Acceptance of this policy
            </div>
            <p>
              You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and
              using the Mobile Application and Services and submitting your information you agree to be bound by this
              Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use
              the Mobile Application and Services.
            </p>
            <div className="policies__main__text__subtitle">
              Contacting us
            </div>
            <p>
              If you have any questions regarding the information we may hold about you or if you wish to exercise your
              rights, you may use the following data subject request form to submit your request:
            </p>
            <p>
              <a href="https://app.websitepolicies.com/dsar/view/i5m85ke2"
                 target="_blank"
                 rel="noopener noreferrer">
                https://app.websitepolicies.com/dsar/view/i5m85ke2
              </a>
            </p>
            <p>
              If you have any other questions, concerns, or complaints regarding this Policy, we encourage you to
              contact
              us using the details below:
            </p>
            <p>
              support@yourstotell.com
            </p>
            <p>
              We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to
              exercise your rights as quickly as possible and in any event, within the timescales provided by applicable
              data protection laws.
            </p>
            <p>
              This document was last updated on October 13, 2022
            </p>
          </div>
        </div>
      </main>

      <Footer />
    </>
  )
}

export default PrivacyPolicy;
