import {Link} from 'react-router-dom';

import {useHeaderScrollStatus} from 'hooks/useHeaderScrollStatus';
import {routes} from 'constants/navigation';

const Header = ({children}) => {
  const [scrolled] = useHeaderScrollStatus();

  return (
    <header className={`header ${scrolled ? "header__scrolled" : ""}`}>
      <div className="header__content">

        <Link className="home-page__main-section__nav__logo"
              to={routes.ROOT.path}>
          <img src="/logo-group.svg"
               alt="Logo" />
        </Link>

        {children}

      </div>
    </header>
  )
}

export default Header
