export const HEADER_SCROLL_POSITION = 50;
export const HOME_PAGE_MAIN_SECTION = "home-page__main-section";
export const FAQ_LIMIT = 6;

export const HOME_PAGE_SECTIONS = {
  about: {
    nav_title: "About",
    section_id: "home-page__about-section"
  },
  faq: {
    nav_title: "FAQ",
    section_id: "home-page__faq-section"
  }
};

export const TELL_YOUR_STORY = [
  "Build a profile to tell your friends and family who you are in your own words",
  "Add photos, videos, audio files, and text stories to tell and preserve the story of the key moments in your life",
  "Use album sub sections to organize your stories in a clear and concise way to build a comprehensive story of you"
];

export const TELL_YOUR_FAMILY_STORY = [
  "Build your family tree to connect to your family members and history",
  "Share family stories photos and memories within your family album to build the story of your family",
  "Give family members access to contribute to albums and subsections within your family album to preserve your shared memories and family stories"
];

export const TELL_YOUR_FRIENDSHIP_STORY = [
  "Easily connect and follow your friends and allow them to follow you",
  "Build shared albums and subsections with your friends to tell your shared stories",
  "Full control of what you see and what you share"
];

export const FAQS = [
  {
    title: "What is Yourstotell?",
    description: "Yourstotell is a social network built to document, organize, and share the key events in your life by building albums to tell your stories. The App allows you to combine all forms of content including photo, video, audio and text that turn each album into a complete story. These stories can then be organized however you see fit to tell and preserve the complete story of your life how you want it told."
  },
  {
    title: "When did it launch?",
    description: "Yourstotell launched in 2023"
  },
  {
    title: "Who owns Yourstotell?",
    description: "Yourstotell is a privately owned family run company."
  },
  {
    title: "Why was Yourstotell created?",
    description: "The founder of Yourstotell was looking for a place to store and document his family history and personal stories that have been passed down that risk being lost generation to generation. After doing some research there was not a place that easily would allow for both organization and sharing without being bombarded by ads and other types of content. With all of the social media sites out there being so focused on driving content at users and sharing sites being too closed, the founder of Yourstotell saw a gap in the market for a social network built entirely for people to store, organize and share their stories without other distractions. The result was Yourstotell which allows each user to both build and organize stories collaboratively with their family and friends or on their own to document and share past stories or build stories dynamically as they are happening."
  },
  {
    title: "How is Yourstotell different than other social media sites?",
    description: "Social Media is exactly what the title implies, media constantly driving content at users based on algorithms and paid post functions. The users on social media sites are the product being sold to those who look to drive their content. Yourstotell is a social network designed to connect people to the stories they wish to share and see not a media company focused on driving content to users. Yourstotell also offers flexibility in how albums are created and organized as opposed to a post by post approach that creates an endless timeline."
  },
  {
    title: "Why is there a subscription charge to post?",
    description: "Subscription fees are the primary source of revenue for Yourstotell. This covers the cost to operate the business. Yourstotell does not sell your data or generate any advertising revenue because this would distract from the overall purpose of the App. Instead of being a product for the App to sell, like with social media sites, the users are the customers. The goal is to provide a distraction free environment where users can both share and see only the stories they want."
  },
  {
    title: "Can I use any features without a subscription?",
    description: "Users can view stories they have permission to view as well as connect to their family via the family tree function without a subscription."
  },
  {
    title: "How do I know I will like Yourstotell before paying?",
    description: "There is a 7 day free trial for anyone prior to the fees kicking in. This will provide any user the opportunity to explore and try the App before committing to paying for it."
  },
  {
    title: "What platforms is Yourstotell available on?",
    description: "Yourstotell is available in the App store and Google Play store."
  },
  {
    title: "Why does Yourstotell ask for my phone number?",
    description: "Yourstotell asks for your phone number when you register for an account in order to confirm your account setup. When you sign up, you’ll receive an SMS with a verification code to the phone number you registered with to confirm your account."
  },
  {
    title: "How do I cancel my subscription?",
    description: "Subscriptions can be cancelled via the subscription management function on your phone prior to the next renewal date."
  }
]
