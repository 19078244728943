export const routes = {
  ROOT: {
    path: '/',
    name: "Home"
  },
  TERMS_OF_USE: {
    path:'/terms-of-use',
    name: "Terms of use"
  },
  PRIVACY_POLICY: {
    path: '/privacy-policy',
    name: "Privacy policy"
  }
}
