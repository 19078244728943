import {ICONS} from 'constants/icons';
import Icon from '../Icon';

const ListBlock = ({titleNotHighlighted, titleHighlighted, list, className, showRightSplash}) => {
  return (
    <div className={`home-page__about-section__image-and-description__text-block ${className}`}>
      <div className="home-page__about-section__image-and-description__text-block__title">
        {titleNotHighlighted}
        <span className="color-orange">&nbsp;{titleHighlighted}</span>
        {showRightSplash && <Icon icon={ICONS.rightSplash}/>}
      </div>
      <ul className="home-page__about-section__image-and-description__text-block__list">
        {list.map((story) => (<li key={story} className="home-page__about-section__image-and-description__text-block__list__item">
            <span><Icon icon={ICONS.listBullet}/></span>
            <span>{story}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ListBlock;
